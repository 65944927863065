document.addEventListener('DOMContentLoaded', () => {
    const videos = document.querySelectorAll('.c-videoLoop');

    if (!videos.length) return;

    const toggleVideo = (video, isFullscreen) => {
        video.volume = 0.25;
        video.muted = !isFullscreen;
        video.play();
        isFullscreen ? video.classList.add('c-videoLoop--fullScreen') : video.classList.remove('c-videoLoop--fullScreen');
    };

    videos.forEach((video) => {
        video.addEventListener('click', () => {
            if (video.requestFullscreen) video.requestFullscreen();
            else if (video.mozRequestFullScreen) video.mozRequestFullScreen();
            else if (video.webkitRequestFullscreen) video.webkitRequestFullscreen();
            else if (video.msRequestFullscreen) video.msRequestFullscreen();
            screen.orientation.lock('portrait-primary').catch(() => {});
        });

        video.addEventListener('fullscreenchange', () => {
            toggleVideo(video, document.fullscreenElement);
        });
    });
});
